<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <div class="text-center leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.firstname }}</p>
      <p class="font-semibold">{{ activeUserInfo.lastname }}</p>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <vs-avatar color="warning" class="flex-shrink-0 mr-2" size="40px" :text="activeUserInfo.firstname + ' ' + activeUserInfo.lastname"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="editProfile">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" class="pr-3" />
            Profile
          </vs-dropdown-item>
          <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
              <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" class="pr-3" />
            Logout
          </vs-dropdown-item>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    editProfile () {
      this.$router.push({'name': 'page-profile', 'params': { userId: this.activeUserInfo.id }}).catch(() => {})
    },
    logout () {
      this.$router.push({'name': 'page-logout'}).catch(() => {})
    }
  }
}
</script>
