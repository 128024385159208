/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: { name: 'partner-add' },
    name: 'My company',
    i18n: 'navbar.menu.company',
    slug: 'my-company',
    icon: null,
    role: 'user'
  },
  {
    url: { name: 'home' },
    name: 'Find a partner',
    i18n: 'navbar.menu.find-a-partner',
    slug: 'find-a-partner',
    icon: null,
    role: 'user'
  },
  {
    name: 'Admin',
    i18n: 'navbar.menu.administration',
    slug: 'Administration',
    icon: 'SlidersIcon',
    role: 'admin',
    submenu: [
      {
        url: { name: 'user-list' },
        name: 'Users',
        i18n: 'navbar.menu.users',
        slug: 'Administration-users',
        icon: 'UserIcon',
        role: 'admin'
      },
      {
        url: { name: 'user-group-list' },
        name: 'Groups',
        i18n: 'navbar.menu.groups',
        slug: 'Administration-groups',
        icon: 'UsersIcon',
        role: 'admin'
      }
    ]
  }
]
