<!-- =========================================================================================
  File Name: TribeNavbarVertical.vue
  Description: Navbar component
  Component Name: TribeNavbarVertical
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper navbar-full p-0 tribe-header" :class="classObj">
      <vs-navbar class="navbar-custom navbar-skelton vs-navbar-flat">
        <!-- SM - OPEN SIDEBAR BUTTON IF USER LOGGED -->
        <div class="flex justify-between w-full" v-if="$acl.check('user')">
          <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
          <vs-spacer />
          <profile-drop-down />
        </div>
        <!-- ELSE -->
        <div class="flex justify-between items-center w-full" v-else>
          <router-link tag="div" to="/" class="vx-logo cursor-pointer flex items-center">
            <logo class="my-3 mr-8 fill-current text-primary" />
          </router-link>
          <login-button />
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import Logo from '../Logo.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import LoginButton from './components/LoginButton.vue'

export default {
  name: 'tribe-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    ProfileDropDown,
    LoginButton,
    Logo
  },
  computed: {
    navbarColorLocal () {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
      return ''
    }
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    }
  }
}
</script>

