<!-- =========================================================================================
  File Name: TribeNavbarHorizontal.vue
  Description: Navbar component
  Component Name: TribeNavbarHorizontal
  ========================================================================================== -->

<template>
<div class="relative">
  <div class="vx-navbar-wrapper navbar-full p-0 tribe-header">
    <vs-navbar class="navbar-skelton navbar-custom vs-navbar-flat">

      <router-link tag="div" to="/" class="vx-logo cursor-pointer flex items-center">
        <logo class="my-3 mr-8 fill-current text-primary" />
      </router-link>

      <ul class="menu-items flex flex-wrap mr-auto items-center">
          <li
            class  = "menu-item"
            v-for  = "(item, index) in menuItemsUpdated"
            :key   = "index"
            :class = "{'mr-2': !(menuItemsUpdated.length === index+1)}">

            <!-- If header -->
            <template v-if="item.header">
              <h-nav-menu-header :header="item" class="menu-header relative" />
            </template>

            <!-- If it's group -->
            <template v-else-if="item.submenu">
              <h-nav-menu-group
                class="menu-group relative py-4"
                bottom
                :key        ="`group-${index}`"
                :group      ="item"
                :groupIndex ="index"
                :open       ="checkGrpChildrenActive(item)" />
            </template>

            <!-- If it's link -->
            <div v-else-if="item.url" class="menu-link">
              <h-nav-menu-item
                class       = "relative py-4 cursor-pointer"
                :to         = "item.slug !== 'external' ? item.url : null"
                :href       = "item.slug === 'external' ? item.url : null"
                :icon       = "item.icon"
                :target     = "item.target"
                :isDisabled = "item.isDisabled"
                :slug       = "item.slug">
                  <span class="truncate">{{ $t(item.i18n) }}</span>
                  <vs-chip :color="item.tagColor" v-if="item.tag">{{ item.tag }}</vs-chip>
              </h-nav-menu-item>
            </div>

          </li>
        </ul>
      <profile-drop-down v-if="$acl.check('user')"/>
      <login-button v-else />
    </vs-navbar>
  </div>
</div>
</template>

<script>
import Logo from '../Logo.vue'
import HNavMenuGroup  from '../horizontal-nav-menu/HorizontalNavMenuGroup.vue'
import HNavMenuHeader from '../horizontal-nav-menu/HorizontalNavMenuHeader.vue'
import HNavMenuItem   from '../horizontal-nav-menu/HorizontalNavMenuItem.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import LoginButton from './components/LoginButton.vue'
const cloneDeep = require('lodash.clonedeep')


export default {
  name: 'tribe-navbar-horizontal',
  props: {
    navMenuItems: { type: Array, required: true }
  },
  components: {
    Logo,
    HNavMenuGroup,
    HNavMenuHeader,
    HNavMenuItem,
    ProfileDropDown,
    LoginButton
  },
  computed: {
    menuItemsUpdated () {
      return cloneDeep(this.navMenuItems).filter(this.aclFilterWithRules)
    }
  },
  methods: {
    checkGrpChildrenActive (group) {
      const path        = this.$route.fullPath
      let active        = false
      const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

      if (group.submenu) {
        group.submenu.forEach((item) => {
          if (active) return true
          if ((path === item.url || routeParent === item.slug) && item.url) { active = true } else if (item.submenu) { this.checkGrpChildrenActive(item) }
        })
      }

      return active
    },
    aclFilterWithRules (item) {
      if (item.role && !this.$acl.check(item.role)) {
        return false
      }

      if (item.submenu) {
        item.submenu = item.submenu.filter(this.aclFilterWithRules)
      }

      if (item.items) {
        item.items = item.items.filter(this.aclFilterWithRules)
      }

      return !item.role || this.$acl.check(item.role)
    }
  }
}
</script>
